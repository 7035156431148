import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ip4regexp } from '@app/app.enums';
import { IServerAddressItemModel } from '@app/core/interfaces/server-address-item-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EButtonStyle } from 'ngx-strong-frontend-lib/components/button';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-server-address-modal',
  templateUrl: './server-address-modal.component.html',
  styleUrl: './server-address-modal.component.scss',
})
export class ServerAddressModalComponent implements OnInit {
  @Input() value: string;
  @Input() onApply: (value: string) => void;

  public readonly EButtonStyle = EButtonStyle;
  public readonly portPattern = /^[0-9]{1,5}$/;
  public readonly form = new FormArray([]);
  public readonly isSubmitted$ = new BehaviorSubject<boolean>(false);

  constructor(
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  public save(): void {
    if (this.form.invalid) {
      const message = this.translateService.instant(
        'SERVER_ADDRESS_MODAL.VALIDATION_ERROR',
      );
      this.toastrService.error(message);

      return;
    }

    const mappedEditValue: string = this.form.value
      .map((item) => `${item.serverAddress}:${item.port}`)
      .join(';');

    this.onApply(mappedEditValue);
    this.activeModal.close();
  }

  public close(): void {
    this.activeModal.close();
  }

  public createServerAddressFormGroup(value?: IServerAddressItemModel): void {
    const formGroup = new FormGroup({
      serverAddress: new FormControl(value?.serverAddress ?? null, [
        Validators.required,
        this.serverAddressValidator(),
      ]),
      port: new FormControl(value?.port ?? null, [
        Validators.required,
        Validators.pattern(this.portPattern),
      ]),
    });

    this.form.push(formGroup);
  }

  public deleteAddressByIndex(index: number): void {
    this.form.removeAt(index);
    this.form.markAsDirty();
  }

  private initForm(): void {
    if (!this.value) {
      return;
    }

    const valueList: string[] = this.value.split(';');

    valueList.forEach((item) => {
      const mappedValueItem: IServerAddressItemModel = {
        serverAddress: item.split(':')[0],
        port: item.split(':')[1],
      };

      this.createServerAddressFormGroup(mappedValueItem);
    });
  }

  private readonly serverAddressValidator: () => ValidatorFn =
    () => (control: FormControl) => {
      const mappedValue: string = control.value?.trim();
      const isValidIpRegexp = !!mappedValue?.match(ip4regexp);
      const isValidHostname = !!mappedValue?.match(/^([a-zA-Z0-9.-])*$/);

      return !isValidIpRegexp || !isValidHostname
        ? { serverAddress: true }
        : null;
    };
}
