<div class="ServerAddressModalClass">
  <div
    class="modal-header"
    translate="SERVER_ADDRESS_MODAL.TITLE">
  </div>
  <div class="modal-body">
    <div class="server-address-modal__content">
      <div class="server-address-modal__content-body scroll-block">
        <div
          class="server-address-modal__content-labels"
          *ngIf="form.controls.length">
          <span
            class="server-address-modal__content-label server-address-modal__address"
            translate="SERVER_ADDRESS_MODAL.ADDRESS">
          </span>
          <span
            class="server-address-modal__content-label server-address-modal__port"
            translate="SERVER_ADDRESS_MODAL.PORT">
          </span>
        </div>
        <div class="server-address-modal__content-values">
          <ng-container
            *ngFor="let addressForm of $any(form.controls); let index = index">
            <div
              class="server-address-modal__content-value-row"
              [formGroup]="addressForm">
              <lib-input
                class="server-address-modal__address"
                inputId="serverAddress"
                [formControl]="addressForm.controls.serverAddress"
                [class.submitted]="isSubmitted$ | async">
              </lib-input>

              <lib-input
                class="server-address-modal__port"
                inputId="port"
                [formControl]="addressForm.controls.port"
                [class.submitted]="isSubmitted$ | async"
                [pattern]="portPattern"
                [patternValue]="portPattern"
                [maxLength]="5">
              </lib-input>

              <lib-button
                [buttonStyles]="[EButtonStyle.ICON]"
                icon="icon-delete"
                iconSize="16"
                (OnClick)="deleteAddressByIndex(index)">
              </lib-button>
            </div>
          </ng-container>
        </div>
        <div class="server-address-modal__content-actions">
          <lib-button
            class="no-padding"
            [title]="
              'SERVER_ADDRESS_MODAL.ADD_SERVER_ADDRESS_BUTTON' | translate
            "
            (OnClick)="createServerAddressFormGroup()">
          </lib-button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <lib-button
      class="modal-footer-btn"
      (OnClick)="close()"
      [title]="'GENERAL.CANCEL' | translate">
    </lib-button>
    <lib-button
      class="modal-footer-btn"
      [disabled]="!form.dirty"
      [buttonStyles]="[EButtonStyle.FILLED]"
      (OnClick)="save()"
      [title]="'GENERAL.SAVE' | translate">
    </lib-button>
  </div>
</div>
